<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/planos" />
        </v-col>
      </v-row>
    </v-card-actions>
    <planos-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      :plano="plano"
      @salvar="salvar"
    />
  </v-container>
</template>
  
  <script>
import planos from "@/api/planos";

export default {
  components: {
    PlanosCampos: () => import("./PlanosCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      plano: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await planos.buscar(this.$route.params.id);
        var plano = {
          descricao: resposta.data.data.descricao,
          complemento: resposta.data.data.complemento,
          tickets: resposta.data.data.tickets,
          valor_mensal: resposta.data.data.valor_mensal,
          valor_anual: resposta.data.data.valor_anual,
          id: resposta.data.data.id,
          ativo: resposta.data.data.ativo,
          default: resposta.data.data.default,
          numero_areas: resposta.data.data.numero_areas,
        };

        this.plano = plano;
      } catch (e) {
        console.log("teste");
        this.$router.push("/planos", () =>
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(plano) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        await planos.atualizar(plano.id, plano);

        this.$router.push("/planos", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          console.log(e.response);
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
  